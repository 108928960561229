.shoutbox-toolbar {
  .p-input-icon-left {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.shoutbox_container_body {
  width: 100%;
  height: 100%;
}

.galleria-container {
  position: relative;
  cursor: pointer;
  width: fit-content;
}
.galleria-container img {
  display: block;
  max-width: 300px;
}
.galleria-container .galleria-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: black;
  cursor: pointer !important;
}

.galleria-container i {
  position: absolute;
  bottom: 50%;
  left: 50%;
  color: white;
  opacity: 0;
}

.galleria-container:hover .galleria-overlay {
  opacity: 0.6;
}

.galleria-container:hover > .galleria-overlay > i {
  opacity: 1 !important;
}

//header
.shoutbox-toolbar {
  padding-left: 1%;
  padding-right: 1%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

//content
.shoutbox-container {
  padding: 0.5% 0 2% 0;
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 80vh;
  width: 100%;
  overflow-y: auto;
}

.shoutbox-element {
  display: flex;
  flex-direction: column;
  min-width: 10%;
  border-radius: 7.5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
  padding: 15px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-wrap: break-word !important;

    gap: 10px;

    h1 {
      font-weight: bolder !important;
      overflow-wrap: break-word !important;
    }

    .actions {
      display: flex;
      gap: 5px;
    }
  }
  .content {
    flex: 1;
    overflow-wrap: break-word;
  }

  .assigned-group-person-container {
    display: flex;
    gap: 10px;
    margin-top: 10px;

    .custom-chip {
      background-color: #2196f3; /* Modern blue color */
      color: #fff; /* White text color */
      border: none;
      border-radius: 20px;
      padding: 1px 10px;
      display: inline-flex;
      align-items: center;
      gap: 1px;
      font-size: 12px;
      transition: background-color 0.2s ease-in-out;
      cursor: default;

      .pi {
        font-size: 0.75rem;
      }

      &:hover {
        background-color: #1565c0;
      }
    }
  }

  .image-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    gap: 10px;
    flex-wrap: wrap;
    flex-direction: column;

    .p-image > img {
      width: 300px;
      height: 300px;
      object-fit: cover;
      object-position: 15%;
      max-width: 100%;
    }

    .media-container {
      position: relative;
      display: inline-block;
    }

    .remove-button {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 20px;
      height: 20px;
      background-color: red; /* Set your desired color */
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 1;
    }

    .remove-button span {
      font-size: 14px;
      color: white;
      line-height: 1;
      margin-top: -3px;
    }
  }

  .chat-icon-container {
    i {
      background-color: transparent !important;
    }
    text-align: center;
    margin: 20px;
  }

  .reaction-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 15px;
    margin-bottom: 2.5px;

    .p-overlay-badge {
      background-color: transparent;
      border-radius: 50%;
      border: 0.5px solid #2196f3;
      cursor: default;
    }

    .add-reaction-button {
      background-color: #2196f3 !important;
      color: white;

      &:hover {
        background-color: #0468bb !important;
      }
    }
  }

  .footer {
    border-top: 1px solid black;
    margin-top: 10px;
    padding-top: 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    :first-child {
      margin-right: 5px;
    }
  }
}

.shoutbox-hover {
  .chat-icon-container {
    i {
      &:hover {
        cursor: pointer !important;
      }
    }
  }
}

@media (max-width: 1600px) {
  .shoutbox-dialog {
    width: 90% !important;
  }
}

//dialog
.shoutbox-dialog {
  width: 45%;

  .delete-content {
    span {
      font-weight: bolder;
    }

    .actions {
      display: flex;
      gap: 10px;
    }
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .add-content {
    margin-top: 20px;

    .actions {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }

    .severity-container {
      display: flex;
      justify-content: center;
      gap: 20px;

      .label-radiobutton {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        i {
          font-size: large;
        }
      }
    }

    .assign-container {
      display: flex;
      justify-content: center;
      gap: 10px;
    }
    .image-upload-container {
      display: flex;
      justify-content: center;

      .file-input {
        display: inline-block;
        background-color: #f0f0f0;
        border-radius: 4px;
        padding: 10px 20px;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;

        &:hover {
          background-color: #e0e0e0;
        }

        input[type="file"] {
          display: none;
        }

        span {
          font-size: 14px;
          color: #333;
        }
      }
    }

    .preview-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.create-group-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  h3 {
    text-align: center;
    margin-top: 0;
    margin-bottom: -10px;
  }
}

.p-invalid-custom {
  input {
    outline: 1px solid red !important;
  }
}
.chat-container {
  height: 900px;
  .chat-block {
    align-items: center;
    margin-top: 20px;

    .p-avatar-text {
      font-size: 15px;
    }
  }
}

.dropbox-group-option {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .actions-buttons {
    display: flex;
    align-items: center;
    i {
      z-index: 999;
    }
    button {
      z-index: 999;
    }
  }
}
