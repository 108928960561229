

.appointment_search_toolbar{
    padding-left: 1%;
    padding-right: 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    div{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
}

.appointments_container{
    padding: 2% 0 2% 0;
    margin-top: 1%;
    display:flex;
    gap: 7.5px;
    flex-wrap: wrap;
    justify-content: center;
    max-height: 80vh;
    width: 100%;
      // Styling for WebKit-based browsers (Chrome, Safari)
  &::-webkit-scrollbar {
    width: 8px; // Set the width of the scrollbar
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0069B5; // Blue color for the scrollbar thumb
    border-radius: 4px; // Rounded corners for the thumb
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #0056b3; // Darker blue color on hover
  }

  &::-webkit-scrollbar-track {
    background-color: #f0f0f0; // Background color for the track
    border-radius: 4px; // Rounded corners for the track
  }

  // Styling for Firefox-based browsers
  scrollbar-color: #0069B5 #f0f0f0; // Blue color for the thumb, background color for the track

  // Optional: Add darker color on hover for the thumb in Firefox
  scrollbar-color: #0056b3 #f0f0f0; // Darker blue on hover
}

.appointment-element{
    width: 20%;
    height: 120px;

   /*  border-radius: 10px 15px; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    padding: 10px;

    .schedule_timeslot{
        height: 100%;

        .second_violin{
          margin-top: 2% !important;
          height: 90% !important;
        }

        .second_violin > div{
          max-height: none !important;
          background: none !important;
          svg{
            color: rgb(132, 215, 132);
          }
        }

        div > strong {
          font-size: 14pt;
        }

        .manually_created_event{
          border: 3px solid red !important;
        }
    
        .event-start-end-hour{
          font-weight: bold;
          margin-top: 2%;
        }

        .event-insurance{
          font-size: 12pt;
          font-weight: bold;
        }
    }

    .manually_created_event{
      border: none !important;
      border-left: 2px solid rgb(146, 15, 15) !important;
      padding-left: 7.5px !important;
    }
    
}

.bright_manually{
  .manually_created_event{
    border-left: 2px solid rgb(235, 172, 172) !important;
  }

}

.input-search{
    .p-inputtext{
        width: 25vw;
    }
}

@media (min-width: 2000px) {
  .appointment-element{
    height: 125px;
  }
}