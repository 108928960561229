.fc-list-event-time, .fc-list-event-graphic {
    display: none;
}

.fc-day-sat, .fc-day-sun {
    background: #faffd8;
}

.vacation_name {
    white-space: break-spaces;
}